import React from 'react';

const AdTraceHeader = ({
    domInstanceResponse,
    isConsentMismatch,
    activeTab = 1,
    setActive,
}) => {
    let aTab = isConsentMismatch && activeTab === 1 ? 'consent' : 'html';
    return (
        <div className="nav flexible mt-1">
            <div className="logo-link">
                <img
                    src="/static/images/Confiant_logo_full.svg"
                    alt="Confiant, Inc"
                />
                <span className="ad-trace-title mt-1 ml-2 flex-column">
                    AD TRACE
                </span>
            </div>

            <div className="flex-5 flex-row ml-1">
                {(isConsentMismatch &&
                    domInstanceResponse &&
                    domInstanceResponse.dom_instance && (
                        <a
                            href="#"
                            role="tab"
                            className={`nav-item nav-link p-3 mr-1 ${
                                aTab === 'consent' ? 'active' : 'inactive'
                            }`}
                            onClick={() => setActive('consent')}
                        >
                            CONSENT DETAILS
                        </a>
                    )) ||
                    null}
                {(domInstanceResponse && domInstanceResponse.dom_instance && (
                    <a
                        href="#"
                        role="tab"
                        className={`nav-item nav-link p-3 ${
                            aTab === 'html' ? 'active' : 'inactive'
                        }`}
                        onClick={() => setActive('html')}
                    >
                        HTML
                    </a>
                )) ||
                    null}
            </div>
        </div>
    );
};

export default AdTraceHeader;
